.filter {
  @apply flex gap-2 p-1.5 relative;

  label {
    @apply flex items-center gap-2 p-1.5 rounded cursor-pointer relative w-full;
  }

  &-container {
    @apply rounded border-4 border-background md:border-0;

    [id*="dropdown-menu"] {
      @apply gap-1;
    }

    [data-controls*="panel"] {
      @apply flex items-center justify-start gap-2 p-1.5 first-of-type:[&>svg]:block last-of-type:[&>svg]:hidden;

      & > span {
        @apply text-gray-2 font-bold;
      }

      > svg {
        @apply w-3.5 h-3.5 text-gray-2 fill-current;
      }

      &[aria-expanded="false"] > svg:last-of-type,
      &[aria-expanded="true"] > svg:first-of-type {
        @apply hidden;
      }

      &[aria-expanded="true"] > svg:last-of-type,
      &[aria-expanded="false"] > svg:first-of-type {
        @apply block;
      }
    }

    input[type="checkbox"],
    input[type="radio"] {
      @apply w-3.5 h-3.5 relative z-10;

      &:checked,
      &:indeterminate {
        @apply accent-secondary;
      }

      &:checked {
        + span {
          @apply text-secondary font-semibold relative z-10;
        }

        + span + span {
          @apply w-full absolute top-0 left-0 bg-background-4 h-full rounded block;
        }
      }
    }

    // nesting cases
    [id*="panel"] [id*="panel"] {
      @apply ml-6;
    }

    & + & {
      @apply mt-4 md:mt-8;
    }
  }

  &-skip {
    @apply absolute z-10 left-0 -translate-x-full py-1 px-4 bg-primary rounded-br-lg text-white cursor-pointer transition focus:translate-x-0;
  }

  &-help {
    @apply text-xs text-gray-2 mb-4;
  }

  &-search {
    @apply w-full relative bg-gray;

    input {
      @apply w-full;
    }

    button {
      @apply absolute right-2 inset-y-2;
    }
  }

  svg {
    @apply w-4 h-4 flex-none text-gray fill-current;
  }

  span {
    @apply text-sm text-gray-2 truncate whitespace-nowrap first-letter:uppercase;
  }

  [data-controls*="panel"] {
    @apply ml-auto p-0 first-of-type:[&>svg]:block last-of-type:[&>svg]:hidden rounded hover:bg-gray-2 hover:[&>svg]:text-white transition-colors;

    &[aria-expanded="false"] > svg:last-of-type,
    &[aria-expanded="true"] > svg:first-of-type {
      @apply hidden;
    }

    &[aria-expanded="true"] > svg:last-of-type,
    &[aria-expanded="false"] > svg:first-of-type {
      @apply block;
    }
  }

  &-container [type="radio"]:checked + &,
  &-container [type="checkbox"]:checked + &,
  &-container [id*="dropdown-menu"] .is-active,
  &:hover {
    @apply bg-background-4;
  }

  &-container [type="radio"]:checked + &,
  &-container [type="checkbox"]:checked + &,
  &-container [id*="dropdown-menu"] .is-active,
  &-container [type="checkbox"]:indeterminate + & {
    svg {
      @apply text-secondary;
    }

    span {
      @apply text-secondary font-semibold;
    }
  }
}

/* overwrite default dropdowns */
[data-target="dropdown-menu-filters"] {
  @apply px-0 justify-start [&>span]:text-gray-2;

  > svg {
    @apply h-4 w-4 text-gray;
  }

  & + [id="dropdown-menu-filters"] {
    @apply mx-0;
  }
}
