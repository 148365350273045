.notification {
  @apply pb-4 last:pb-0 flex items-start justify-between gap-4 transition duration-500;

  & + & {
    @apply border-t border-gray-3 pt-4;
  }

  &__wrapper {
    @apply grow flex flex-col-reverse md:flex-row gap-2 md:gap-4;
  }

  &__time {
    @apply w-full md:w-1/6 text-gray-2 break-words flex-none first-letter:uppercase text-sm md:text-md;
  }

  &__snippet {
    @apply flex flex-col grow space-y-1;

    &-title {
      @apply prose-a:font-semibold hover:prose-a:underline prose-a:text-secondary;
    }

    &-actions {
      @apply pt-4;
    }

    &-resource {
      @apply text-sm text-gray-2 flex items-center gap-1;

      svg {
        @apply text-gray fill-current;
      }
    }
  }

  &__button {
    @apply bg-background rounded flex items-center gap-1 py-1 px-2 text-xs text-secondary whitespace-nowrap flex-none transition hover:bg-secondary hover:text-white;
  }
}
